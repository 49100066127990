export const newsList = [
  {
    id: 326,
    src: require('../../assets/images/mainPageImages/newsImages/news-326.jpg'),
    title: 'Звезда по имени мама',
    description: 'В красивую дату, 24.11.24, на сцене Краснопахорского дома культуры в честь Дня матери сияли претендентки на звание главной "Звезды по имени МАМА". Молодые и жизнерадостные, креативные и творческие девушки представили дефиле, визитку, ответили на вопросы интеллектуальной викторины и покорили сердца жюри своими талантами в заключительном этапе ежегодного конкурса.По итогу всех испытаний Ксения Ларионова лучшая в номинации "Талант и одаренность". Екатерина Шупикова удостоилась звания "Стильная и женственная". Диана Уберти по праву признана обладательницей диплома "Добрая, мудрая и понимающая". Жанна Гаспарян взяла букет наград. Именно Жанна - "Креативная и позитивная", лидер голосования зрителей и "ЗВЕЗДА ПО ИМЕНИ МАМА" 2024. Мы поздравляем с Днём матери участниц и судей, организаторов и гостей нашего праздника! Будьте любимы, здоровы и счастливы!'
  },
  {
    id: 325,
    src: require('../../assets/images/mainPageImages/newsImages/news-325.jpg'),
    title: 'Кто много читает',
    description: 'Кто много читает, не только много знает, но и получает сладкие призы за правильные ответы в викторинах. Одна из познавательных и развлекательных встреч для детей прошла в библиотеке посёлка Минзаг 16 ноября. Заведующая Краснопахорской библиотечной системы  Людмила Абрамова сопроводила юных читателей в книжном путешествии по "СТРАНЕ ДЕДА МОРОЗА", где ребята разгадывали загадки, отвечали на вопросы викторины и справились со всеми заданиями литературного кроссворда. Предновогодняя выставочная экспозиция ярких иллюстрированных изданий работает до конца месяца в читальном зале Минзага.'
  },
  {
    id: 324,
    src: require('../../assets/images/mainPageImages/newsImages/news-324.jpg'),
    title: 'Мамы нежная улыбка',
    description: 'Последнее воскресенье ноября в этом году приходится на 24 число. Именно тогда мы отметим ДЕНЬ МАТЕРИ. В преддверии этого праздника в доме культуры "Юбилейный" открыли фотовыставку: "Мамы нежная улыбка", которая продлится до конца ноября. В экспозиции представлены работы  специалиста по связям с общественностью Краснопахорского дома культуры Инны Чистовой: это репортажные и постановочные снимки, сделанные во время мероприятий ДК  "Звёздный" и ДК  "Юбилейный", а также фотографии из семейных архивов ребят, которые занимаются в наших кружках и студиях. Дорогие и любимые наши МАМЫ, мы поздравляем вас с праздником, желаем крепкого здоровья, терпения и ЖЕНСКОГО СЧАСТЬЯ! А главное помните: неважно, сколько лет нашим детям, их радости нет предела, когда они видят МАМЫ НЕЖНУЮ УЛЫБКУ'
  },
  {
    id: 323,
    src: require('../../assets/images/mainPageImages/newsImages/news-323.jpg'),
    title: 'День рождения главного волшебника',
    description: 'Мастер-класс, игры и знакомство с Дедом Морозом 18 ноября в честь Дня рождения главного зимнего волшебника для детей Краснопахорского района и их родителей в доме культуры "Звёздный" прошла творческая развлекательная встреча. Елена Михайлина помогла ребятам изготовить новогодние открытки с цветными аппликациями. Вместе с Мадиной Романчевой и Дедом Морозом дети и подростки выполняли задания весёлых конкурсов, танцевали и водили хоровод!'
  },
  {
    id: 322,
    src: require('../../assets/images/mainPageImages/newsImages/news-322.jpg'),
    title: 'Премьера спектакля',
    description: '2 ноября в концертном зале дома культуры "Звёздный" актёры студии "Мыслите" представили новый спектакль. Постановку по произведению Мариам Петросян "Дом в котором" очень тепло приняли зрители. В их сердцах нашли отклик сложные темы выбора в разных жизненных ситуациях, правды и лжи, верности и предательства. Людмила Герасимова, режиссёр студии "Мыслите": "В репертуаре нашего коллектива появился ещё один спектакль. Ребята справились с глубоким современным материалом. Я их поздравляю. ВСЕ БОЛЬШИЕ МОЛОДЦЫ!"'
  },
  {
    id: 321,
    src: require('../../assets/images/mainPageImages/newsImages/news-321.jpg'),
    title: 'Осенний вернисаж',
    description: 'Снег отражает почти все солнечные лучи, поэтому он имеет цвет света, которым освещён. Солнечный свет белый, поэтому снег тоже белый. При этом ученые выяснили, что красно-розовый оттенок снегу придают водоросли Хламидомонада снежная (лат. Chlamydomonas nivalis). Этот вид одноклеточных зеленых водорослей развивается на поверхности воды, льда и снега. Как только снег начинает чуть греться, то водоросли розовеют. "Смелее, смелее! Смотрите, как красиво получается! Добавьте чуть-чуть розового, а здесь можно лиловых оттенков! Разве может художник оставить снег белым?",- спрашивает у ребят руководитель студии изобразительного искусства Олеся Мартынова. 7 ноября вместе с Олесей Владимировной юные живописцы дома культуры "Звёздный" трудились над акварельными пейзажами. Увидеть эти яркие работы мы сможем на ближайших выставках! Следите за нашими афишами'
  },
  {
    id: 320,
    src: require('../../assets/images/mainPageImages/newsImages/news-320.jpg'),
    title: 'Книжная экспозиция',
    description: 'От ВОИНСКОЙ СЛАВЫ к ЕДИНСТВУ РОССИИ Библиотекарь Ольга Горячкина приглашает познакомиться с новой книжной экспозицией. Выставка в читальном зале детской Краснопахорской библиотеки работает со 2 по 16 ноября. Ольга Антоновна Горячкина: "Наши юные читатели - настоящие патриоты. И мальчики, и девочки интересуются историей России. Многие помнят наизусть Дни воинской славы, важные даты сражений. Мы в свою очередь всеми силами поддерживаем ребят на пути к новым знаниям!"'
  },
  {
    id: 319,
    src: require('../../assets/images/mainPageImages/newsImages/news-319.jpg'),
    title: 'Мастер - класс',
    description: 'Татьяны Гарифуллиной прошёл в ДК "Юбилейный" 1 октября. Вместе с Татьяной Николаевной ребята изготовили из цветной бумаги необычные плетёные корзиночки - подарки для любимых бабушек. Смотрите, что получилось!'
  },
  {
    id: 318,
    src: require('../../assets/images/mainPageImages/newsImages/news-318.jpg'),
    title: 'Уроки шитья',
    description: 'Стежок: "НАЗАД ИГОЛКУ" освоили участницы мастер-класса Л. М. Матвеевой 26 сентября в ДК "Звёздный" прошёл мастер-класс по шитью ко Дню старшего поколения под названием: "Милая, родная - бабушка моя!" Ведь кто, если ни наши бабули, знают всё о рукоделии? Руководитель студии "Ателье для кукол" Лариса Матвеева рассказала детям, какие бывают швы и показала технику выполнения некоторых их них. А вы умеете шить, вышивать или вязать? Ставьте, если ДА! Ставьте, если КОНЕЧНО!'
  },
  {
    id: 317,
    src: require('../../assets/images/mainPageImages/newsImages/news-317.jpg'),
    title: 'Концерт для военнослужащих',
    description: 'Сегодня, 24 сентября, вместе с участниками художественной самодеятельности Дома культуры "Звездный" поздравили служащих воинской части с днем ее основания. В концертной программе были представлены лучшие творческие номера: вокальные и хореографические. Артистов военнослужащие встречали и провожали бурными овациями. Мне очень приятно, что творчество наших краснопахорцев отзывается неподдельной искренностью в сердцах людей, а тем более защитников нашей Родины!'
  },
  {
    id: 316,
    src: require('../../assets/images/mainPageImages/newsImages/news-316.jpg'),
    title: 'Беседа',
    description: 'В доме культуры «Юбилейный» состоялась беседа, посвященная Дню солидарности в борьбе с терроризмом.Этот день приурочен к трагическим событиям, произошедшим 1-3 сентября 2004 года в городе Беслан (Северная Осетия), когда в результате беспрецедентного по своей жестокости террористического акта погибли более 300 человек, в основном женщины и дети. 3 сентября Россия отдает дань памяти тысячам соотечественников, погибшим от рук террористов'
  },
  {
    id: 315,
    src: require('../../assets/images/mainPageImages/newsImages/news-315.jpg'),
    title: 'Собери ребенка в школу',
    description: 'Всероссийскую акцию «Собери ребенка в школу» провели в Краснопахорском. 27 ребят получили портфели и школьные принадлежности к началу учебного года. Первоклашек и школьников поздравили глава Управы Краснопахорского района Юрий Няньчур, председатель Совета ветеранов Лидия Безрукова, директор ДК «Звездный» Надежда Матвеева'
  },
]

export const moscowEvents = [
    {
      id: 2,
      type: 'Интерактивная, Классическое искусство',
      name: 'Айвазовский. Кандинский. Живые полотна',
      place: 'Пространство «Люмьер-холл»',
      date: 'до 23 апреля 2023',
      description: 'На выставке представлено более 100 работ Айвазовского из музеев по всей России, большинство из них сделано в формате ps-3D, что позволяет посетителям почувствовать себя внутри картины. А мощные проекторы высокого разрешения дополненные объемным звуком перенесут посетителей за тысячи километров в акваторию Черного моря на место известных корабельных сражений.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-2.jpg'),
      to: 'https://www.afisha.ru/exhibition/258674/'
    },
    {
      id: 3,
      type: 'Выставка в Москве',
      name: 'Первые моторы России',
      place: 'Музей гаража особого назначения ФСО России',
      date: 'до 27 октября 2023',
      description: 'О первых страницах истории русского автомобилизма — зарождении технической культуры, творчества, инженерной школы — расскажет выставка «Первые моторы России» в Музее Гаража особого назначения на ВДНХ.Павильон 54 музея превращен в зал IV Международной автомобильной выставки. В исторических интерьерах представлены уникальные экспонаты из коллекции Политехнического музея, находившиеся в запасниках и впервые собранные вместе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-3.jpeg'),
      to: 'https://www.afisha.ru/exhibition/272590/'
    },
    {
      id: 4,
      type: 'ВЫСТАВКА В МОСКВЕ',
      name: 'Космонавтика и авиация',
      place: 'Павильон «Космос»',
      date: 'Постоянная экспозиция',
      description: 'В основной экспозиции — огромный архив документальных свидетельств покорения космоса: 120 образцов одной только техники и больше 2 тысяч разных документов, включая фото и видео. Под куполом расположен 5D-кинотеатр, который рассказывает о Большом взрыве и светлом будущем с колонией на Марсе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-4.jpg'),
      to: 'https://www.afisha.ru/exhibition/208082/'
    }
]